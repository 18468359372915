<template>
  <page-container title="终端自检异常" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 20px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-calendar :header-render="headerRender" @select="onSelect" @panelChange="onPanelChange">
            <ul slot="dateCellRender" slot-scope="value" class="events">
              <li v-for="item in getListData(value)" :key="item.content">
                <a-badge :status="item.type" :text="item.content" />
              </li>
            </ul>
            <template slot="monthCellRender" slot-scope="value">
              <div v-if="getMonthData(value)" class="notes-month">
                <section>{{ getMonthData(value) }}</section>
                <span>Backlog number</span>
              </div>
            </template>
          </a-calendar>
        </div>
      </div>
    </div>
    <dev-abnormal-log-modal :visible.sync="modalVisible" :dev-detail="devDetail"></dev-abnormal-log-modal>
  </page-container>
</template>
<script>
import moment from 'moment'
import pagination from '@/mixins/pagination'
import devAbnormalLogModal from "V/monitorDevice/selfTest/devSelfTest/devAbnormalLogModal";
import {findDevAbnormalData, findDevTestLogListByCondition} from "A/ai";
export default {
  name:"devAbnormalLog",
  mixins: [pagination],
  components:{
    devAbnormalLogModal,
  },
  data() {
    return {
      moment,
      breadcrumb: [
        {
          name: '网络管理',
          path: ''
        },
        {
          name: '自检管理',
          path: ''
        },
        {
          name: '终端自检异常',
          path: ''
        },
      ],
      modalVisible: false,
      sonModalVisible:false,
      devDetail:'',
      testList: [],
    }
  },
  created() {
    this.getAbnormalData()
  },
  methods: {
    getAbnormalData(){
      let time = moment(new Date()).format('YYYY-MM')
      let params = {
        start_time:time+"-01",
        end_time:time+"-31",
        status:'1',
      }
      findDevAbnormalData(params).then(res => {
        if(res&&res.returncode=='0'){
          this.testList=res.item
        }
      })
    },
    getListData(value) {
      let listData;
      this.testList.forEach(e => {
        //让数组的date与遍历到日历的那天的日期值相匹配
        if (e.date === value.format("YYYY-MM-DD")) {
          listData = e.listData;
        }
      });
      return listData || [];
    },
    getMonthData(value) {
      if (value.month() === 8) {
        return 1394;
      }
    },
    onSelect(value) {
      this.devDetail=moment(value._d).format("YYYY-MM-DD")
      this.modalVisible=true
    },
    onPanelChange(value, mode) {
      let time = moment(value._d).format("YYYY-MM")
      let params = {
        start_time:time+"-01",
        end_time:time+"-31",
        status:'1',
      }
      findDevAbnormalData(params).then(res => {
        if(res&&res.returncode=='0'){
          this.testList=res.item
        }
      })
    },
    headerRender({ value, type, onChange, onTypeChange }) {
      const start = 0;
      const end = 12;
      const monthOptions = [];

      const current = value.clone();
      const localeData = value.localeData();
      const months = [];
      for (let i = 0; i < 12; i++) {
        current.month(i);
        months.push(localeData.monthsShort(current));
      }

      for (let index = start; index < end; index++) {
        monthOptions.push(
            <a-select-option class="month-item" key={`${index}`}>
              {months[index]}
            </a-select-option>,
        );
      }
      const month = value.month();

      const year = value.year();
      const options = [];
      for (let i = year - 10; i < year + 10; i += 1) {
        options.push(
            <a-select-option key={i} value={i} class="year-item">
              {i}
            </a-select-option>,
        );
      }
      return (
          <div style={{ padding: '10px' }}>
            <div style={{ marginBottom: '10px'}}></div>
            <a-row type="flex">
              <a-col>
                <a-select
                    size="larger"
                    dropdownMatchSelectWidth={false}
                    class="my-year-select"
                    onChange={newYear => {
                      const now = value.clone().year(newYear);
                      onChange(now);
                    }}
                    value={String(year)}
                >
                  {options}
                </a-select>
              </a-col>
              <a-col>
                <a-select
                    style="margin-left:15px"
                    size="larger"
                    dropdownMatchSelectWidth={false}
                    value={String(month)}
                    onChange={selectedMonth => {
                      const newValue = value.clone();
                      newValue.month(parseInt(selectedMonth, 10));
                      onChange(newValue);
                    }}
                >
                  {monthOptions}
                </a-select>
              </a-col>
            </a-row>
          </div>
      );
    },
  },
}
</script>
<style scoped>
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>